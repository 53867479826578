import React, { useEffect, useState } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { globalStore } from '~/store';
import { getHash } from '~/utils';

const safeGetProperty = (obj: any, prop: string) => {
  try {
    return obj[prop];
  } catch (e) {
    return undefined;
  }
};

const getNavigatorData = (): Record<string, any> => {
  const data: Record<string, any> = {};
  for (const key in navigator) {
    try {
      data[key] = (navigator as any)[key];
    } catch (e) {
      data[key] = 'Access denied';
    }
  }
  return data;
};

const getNavigatorKeys = (): string[] => {
  const data = getNavigatorData();
  return Object.keys(data);
};

const isEmpty = (value: any): boolean => {
  if (value === null || value === undefined || value === '') return true;
  if (typeof value === 'object' && !Array.isArray(value)) return Object.keys(value).length === 0;
  if (Array.isArray(value)) return value.length === 0;
  return false;
};

const formatPlugins = (plugins: any): string[] => {
  const result: string[] = [];
  for (let i = 0; i < plugins.length; i++) {
    const plugin = plugins[i];
    result.push(`${plugin.name}, ${plugin.filename}, ${plugin.description}`);
  }
  return result;
};

const formatMimeTypes = (mimeTypes: any): string[] => {
  const result: string[] = [];
  for (let i = 0; i < mimeTypes.length; i++) {
    const mimeType = mimeTypes[i];
    result.push(`${mimeType.type}, ${mimeType.description}, ${mimeType.suffixes}`);
  }
  return result;
};

const NavigatorProperties: React.FC = () => {
  const [navigatorObject, setNavigatorObject] = useState<Record<string, any>>({});
  const [navigatorHash, setNavigatorHash] = useState<string | null>(null);

  useEffect(() => {
    const getNavigatorProperties = async () => {
      const properties: string[] = [];
      const navigatorData: Record<string, any> = {};

      for (const key in navigator) {
        const value = safeGetProperty(navigator, key);
        if (!isEmpty(value) && typeof value !== 'function') {
          if (key === 'plugins') {
            const formattedPlugins = formatPlugins(value);
            navigatorData[key] = formattedPlugins;
          } else if (key === 'mimeTypes') {
            const formattedMimeTypes = formatMimeTypes(value);
            navigatorData[key] = formattedMimeTypes;
          } else {
            navigatorData[key] = value;
          }
          properties.push(key);
        }
      }

      setNavigatorObject(navigatorData);

      const hash = await getHash(navigatorData);
      globalStore.set({ ...globalStore.get(), navigator: { hash: hash, data: navigatorData } });
      setNavigatorHash(hash); 
    };

    getNavigatorProperties();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">Navigator <span className="text-sm border px-1">{navigatorHash?.slice(0, 12)}</span></h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <code className='break-all mb-5'>{getNavigatorKeys().join(', ')}</code>
        <JsonView src={navigatorObject} />
      </div>
    </div>
  );
};

export default NavigatorProperties;
